<template>
  <div>
    <div :class="['mytitle',id_title[0]]" v-for="(id_title) in title_arr"  v-if="flag_title">
      <div class="text_div">{{ id_title[1] }}</div>
      <div class="low_div"></div>
    </div>
  </div>
</template>

<script>
import $ from "jquery"

export default {
  name: "upTitle",
  data() {
    return {
      title_arr: [],
      name_title: 0,
      flag_title: 0,
    }
  },
  mounted() {
    this.$root.$on("uptitle", (flag_position, position) => {
      this.title_arr.push(position[0])
      this.up_Title(flag_position, position)
    })
  },
  watch: {},
  methods: {
    up_Title(flag, position) {
      if (flag === 1) {
        //this.name_title = position[0]  /*  */
        this.flag_title = true
        this.$nextTick(() => {
          let $mytitle = $('.'+position[0][0])    //[0][0] 为传来的文献id
          $mytitle.css({
            left: position[1] - $mytitle.outerWidth() / 2 + position[3] / 2,
            top: position[2] - $mytitle.outerHeight() - 6,
            opacity: "1", transition: "opacity 0.3s"
          })
        })
      }
      if (flag === 0) {
        //this.flag_title = false
        this.title_arr = []
        $('.mytitle').css({opacity: "0", transition: "opacity .3s"})
      }
    },
  }
}

</script>

<style scoped>
.mytitle {
  opacity: 0;
  z-index: 101;
  position: fixed;
  max-width: 250px;
  padding: 4px 6px;
  background: rgba(40, 40, 40, 0.9);
  border: solid 1px #e9f7f6;
  border-radius: 5px;
  pointer-events: none;
  font-family: "Times New Roman",serif;
}
.text_div {
  color: #ffffff;
  font-size: 12px;
  text-align: center;
}
.low_div {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -20%) rotate(45deg);
  height: 8px;
  width: 8px;
  background: rgba(40, 40, 40, 0.9);
  pointer-events: none;
}
</style>
